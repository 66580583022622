//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysUserAdd } from '@/api/modular/system/userManage'
import { getOrgTree, getOrgList } from '@/api/modular/system/orgManage'
import { sysPosList } from '@/api/modular/system/posManage'
import moment from 'moment'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 機構行樣式
      labelCol_JG: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol_JG: {
        xs: { span: 24 },
        sm: { span: 20 }
      },
      count: 1,
      columns: [
        {
          title: '附屬機構',
          dataIndex: 'extOrgId',
          width: '45%',
          scopedSlots: { customRender: 'extOrgId' }
        },
        {
          title: '附屬崗位',
          dataIndex: 'extPosId',
          width: '45%',
          scopedSlots: { customRender: 'extPosId' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      visible: false,
      confirmLoading: false,
      orgTree: [],
      orgList: [],
      posList: [],
      sysEmpParamExtList: [],
      memberLoading: false,
      form: this.$form.createForm(this),
      data: [],
      birthdayString: []
    }
  },
  methods: {
    // 初始化方法
    add () {
      this.visible = true
      this.getOrgDate()
      this.getPosList()
    },
    /**
     * 增行
     */
    newMember () {
      const length = this.data.length
      this.data.push({
        key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
        extOrgId: '',
        extPosId: '',
        editable: true,
        isNew: true
      })
    },
    /**
     * 刪除
     */
    remove (key) {
      const newData = this.data.filter(item => item.key !== key)
      this.data = newData
    },
    /**
     * 選擇子表單單項觸發
     */
    handleChange (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.data = newData
      }
    },
    /**
     * 獲取機構樹，並加載於表單中
     */
    getOrgDate () {
      getOrgTree().then((res) => {
        this.orgTree = res.data
      })
      getOrgList().then((res) => {
        this.orgList = res.data
      })
    },
    /**
     * 獲取職位list列表
     */
    getPosList () {
      sysPosList().then((res) => {
        this.posList = res.data
      })
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('請確認兩次輸入密碼的一致性！')
      } else {
        callback()
      }
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    /**
     * 選擇樹機構，初始化機構名稱於表單中
     */
    initrOrgName (value) {
      this.form.getFieldDecorator('sysEmpParam.orgName', { initialValue: this.orgList.find(item => value === item.id).name })
    },
    /**
     * 子表單json重構
     */
    JsonReconsitution () {
      this.sysEmpParamExtList = []
      const newData = [...this.data]
      newData.forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (item.extOrgId != '' & item.extPosId != '') {
          this.sysEmpParamExtList.push({ orgId: item.extOrgId, posId: item.extPosId })
        }
      })
    },
    /**
     * 日期需單獨轉換
     */
    onChange (date, dateString) {
      if (date == null) {
        this.birthdayString = []
      } else {
        this.birthdayString = moment(date).format('YYYY-MM-DD')
      }
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          this.JsonReconsitution()
          values.sysEmpParam['extIds'] = this.sysEmpParamExtList
          if (this.birthdayString.length > 0) {
            values.birthday = this.birthdayString
          }
          sysUserAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
      // 清理子表單中數據
      this.data = []
      // 清理時間
      this.birthdayString = []
    }
  }
}
